import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPaymentStatus } from '../../services/api';
import { usePurchaseContext } from '../../contexts/PurchaseContext';
import './OxapayCheckout.css';

const OxapayCheckout = ({ paymentUrl }) => {
  const navigate = useNavigate();
  const { purchaseId } = usePurchaseContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!purchaseId) {
      console.error('No purchaseId available in OxapayCheckout');
      return;
    }

    const checkStatus = async () => {
      try {
        const result = await checkPaymentStatus(purchaseId);
        console.log('Payment status:', result);

        if (result.status === 'Paid' || result.status === 'Confirming' || result.internalStatus === 'payment_confirmed') {
          navigate('/payment-processing');
        } else if (result.status === 'Expired' || result.status === 'Cancelled') {
          navigate('/payment-cancelled');
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
      }
    };

    const intervalId = setInterval(checkStatus, 5000);

    return () => clearInterval(intervalId);
  }, [purchaseId, navigate]);

  if (!purchaseId) {
    return <div className="error-message">Error: No purchase ID available</div>;
  }

  return (
    <div className="oxapay-checkout">
      <div className="checkout-message">
        <p>You will be redirected after successful payment.</p>
      </div>
      {isLoading && (
        <div className="loading-screen">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className="iframe-container">
        <iframe 
          src={paymentUrl} 
          title="Oxapay Checkout" 
          className="checkout-iframe" 
          onLoad={() => setIsLoading(false)}
        />
      </div>
    </div>
  );
};

export default OxapayCheckout;