import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { checkPaymentStatus } from '../../services/api';
import { usePurchaseContext } from '../../contexts/PurchaseContext';
import { FaCheckCircle, FaSpinner } from 'react-icons/fa';
import './PaymentProcessing.css';

const PaymentProcessing = () => {
  const { purchaseId, clearPurchaseId } = usePurchaseContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [message, setMessage] = useState('Processing payment...');
  const [showMyNFTsLink, setShowMyNFTsLink] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const steps = [
    { name: 'Payment Confirmation', status: 'pending' },
    { name: 'Minting NFT', status: 'pending' },
    { name: 'Minting Completed', status: 'pending' }
  ];

  useEffect(() => {
    if (!purchaseId) {
      console.error('No purchaseId provided in PaymentProcessing');
      setMessage('Error: Unable to process payment');
      return;
    }

    console.log('Processing payment for purchaseId:', purchaseId);

    const checkStatus = async () => {
      try {
        const result = await checkPaymentStatus(purchaseId);
        console.log('Payment status:', result);

        if (result.status === 'Paid' || result.internalStatus === 'payment_confirmed') {
          setCurrentStep(1);
          setMessage('Payment confirmed. NFT minting in progress.');
        } else if (result.internalStatus === 'completed') {
          setCurrentStep(2);
          setMessage('NFT minted successfully!');
          setShowMyNFTsLink(true);
          setIsCompleted(true);
          clearPurchaseId();
        } else if (result.internalStatus === 'payment_completed_minting_failed') {
          setMessage('Minting process is taking longer than expected. Please check your My NFTs page in a few minutes.');
          setShowMyNFTsLink(true);
        } else {
          setCurrentStep(0);
          setMessage('Waiting for payment confirmation...');
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        setMessage('Error checking payment status. Please try again.');
      }
    };

    const intervalId = setInterval(checkStatus, 5000);

    return () => clearInterval(intervalId);
  }, [purchaseId, clearPurchaseId]);

  if (!purchaseId && !isCompleted) {
    return <div>Error: No purchase ID available</div>;
  }

  return (
    <div className="payment-processing">
      <h2>Payment Processing</h2>
      <p className="status-message">{message}</p>
      <div className="status-steps">
        {steps.map((step, index) => (
          <div key={index} className={`status-step ${index < currentStep ? 'completed' : index === currentStep ? 'current' : ''}`}>
            <div className="step-icon">
              {index < currentStep ? <FaCheckCircle /> : 
               index === currentStep ? <FaSpinner className="fa-spin" /> : 
               null}
            </div>
            <div className="step-name">{step.name}</div>
          </div>
        ))}
      </div>
      {showMyNFTsLink && (
        <div className="my-nfts-link">
          <p>It may take a few minutes for the blockchain to confirm the minting process.</p>
          <p>You can check your My NFTs section to see your new NFT.</p>
        </div>
      )}
    </div>
  );
};

export default PaymentProcessing;