import React from 'react';
import '../styles/ErrorPage.css';

const ErrorPage = ({ message }) => {
  return (
    <div className="error-page">
      <h1>Oops! Something went wrong</h1>
      <p>{message || "We're currently under maintenance. Please check back in a few minutes."}</p>
      <p>We apologize for the inconvenience and appreciate your patience.</p>
      <button onClick={() => window.location.reload()}>Refresh Page</button>
    </div>
  );
};

export default ErrorPage;