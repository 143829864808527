import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import {
  initializeUser,
  setTelegramInitData,
  getUserStats,
  fetchAndUpdateProfilePhoto,
} from '../services/api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
  const updateIntervalRef = useRef(null);

  const initUser = useCallback(async () => {
    try {
      setLoading(true);

      const tg = window.Telegram.WebApp;
      if (!tg.initDataUnsafe || !tg.initDataUnsafe.user) {
        throw new Error('Telegram WebApp data is not available');
      }

      setTelegramInitData(tg.initData);

      // Extract referral code from tgWebAppStartParam
      const startParam = new URLSearchParams(window.location.search).get(
        'tgWebAppStartParam'
      );
      //console.log('tgWebAppStartParam:', startParam);
      const referralCode =
        startParam && startParam.startsWith('ref_') ? startParam.slice(4) : null;
      //console.log('Extracted referralCode:', referralCode);

      const userData = {
        telegramInitData: tg.initData,
        telegram_id: tg.initDataUnsafe.user.id.toString(),
        referral_code: referralCode,
      };

      //console.log('Initializing user with data:', userData);

      const response = await initializeUser(userData);
      if (!response || !response.user) {
        throw new Error('Invalid user data received from server');
      }

      console.log('User initialized:', response.user);

      // Process referral, if applicable
      if (response.referralBonus) {
        console.log('Referral bonus applied:', response.referralBonus);
      }

      // Process joining bonus, if applicable
      if (response.joiningBonus) {
        console.log('Joining bonus applied:', response.joiningBonus);
      }

      // Update user state with all received data
      setUser(response.user);

      if (response.token) {
        localStorage.setItem('authToken', response.token);
      }

      // Attempt to update profile picture
      try {
        const photoResult = await fetchAndUpdateProfilePhoto(
          tg.initDataUnsafe.user.id
        );
        if (photoResult.photoUrl) {
          setProfilePhotoUrl(photoResult.photoUrl);
         // console.log('Profile picture updated:', photoResult.photoUrl);
        } else {
         // console.log('No new profile picture available');
        }
      } catch (photoError) {
        console.error('Failed to update profile picture:', photoError);
        // Don't throw here, as profile picture update is not critical
      }

      // Fetch additional user stats if needed
      const additionalStats = await getUserStats();
      setUser((prevUser) => ({ ...prevUser, ...additionalStats }));

     // console.log('User initialization completed successfully');
    } catch (error) {
      console.error('Failed to initialize user:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []); // Empty dependency array to ensure initUser doesn't change

  useEffect(() => {
    initUser(); // Call initUser once when component mounts

    return () => {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
      }
    };
  }, [initUser]); // Include initUser in dependencies

  const updateUser = useCallback(
    async (newData) => {
      if (user && user.id) {
        try {
          if (newData) {
            setUser((prevUser) => ({ ...prevUser, ...newData }));
          } else {
            const updatedStats = await getUserStats();
            setUser((prevUser) => ({ ...prevUser, ...updatedStats }));
          }
        } catch (error) {
          console.error('Failed to update user stats:', error);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    if (user) {
      // Start updating user stats every 60 seconds
      updateIntervalRef.current = setInterval(() => updateUser(), 60000);
    }

    return () => {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
      }
    };
  }, [user, updateUser]);

  const logout = useCallback(() => {
    setUser(null);
    setProfilePhotoUrl(null);
    localStorage.removeItem('authToken');
    if (updateIntervalRef.current) {
      clearInterval(updateIntervalRef.current);
    }
  }, []);

  const value = {
    user,
    setUser,
    loading,
    logout,
    error,
    updateUser,
    profilePhotoUrl,
    setProfilePhotoUrl,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};