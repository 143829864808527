import React, { useState, useEffect } from 'react';
import { getNFTCatalogue, getUserNFTs, initiateOxapayPayment } from '../services/api';
import MyNFTs from '../components/NFTs/MyNFTs';
import { usePurchaseContext } from '../contexts/PurchaseContext';
import { FaCoins, FaShoppingCart, FaExchangeAlt, FaWallet, FaUser } from 'react-icons/fa';
import '../styles/NFTs.css';
import liteImage from '../styles/img/lite.png';
import proImage from '../styles/img/pro.png';

const NFTCard = ({ nft, onBuyClick, onCompareClick }) => {
  const [showBenefits, setShowBenefits] = useState(false);

  const getImageSource = () => {
    if (nft.name.toLowerCase().includes('lite')) {
      return liteImage;
    } else if (nft.name.toLowerCase().includes('pro')) {
      return proImage;
    }
    return nft.image;
  };

  const getBenefits = () => {
    if (nft.name.toLowerCase().includes('lite')) {
      return [
        "Extra Airdrop at TGE",
        "EDG Token rewards for 3 months",
        "Partner Project Airdrop"
      ];
    } else if (nft.name.toLowerCase().includes('pro')) {
      return [
        "Extra Airdrop at TGE",
        "EDG Token rewards for 1 year",
        "Multiple Token rewards (DOT, KSM, ETH) from staking pool",
        "Early Access to Smartphone project",
        "Buy Smartphone in first batch (exclusive to NFT holders)",
        "Partner Project Airdrop",
        "More benefits coming soon"
      ];
    }
    return [];
  };

  return (
    <div className="nft-card">
      <div className="nft-image-container">
        <img src={getImageSource()} alt={nft.name} className="nft-image" />
      </div>
      <div className="nft-info">
        <h3>{nft.name}</h3>
        <p className="nft-price"><FaCoins /> ${nft.price}</p>
        <button onClick={() => setShowBenefits(!showBenefits)} className="benefits-button">
          {showBenefits ? 'Hide Benefits' : 'Show Benefits'}
        </button>
        {showBenefits && (
          <div className="nft-benefits">
            <ul>
              {getBenefits().map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </div>
        )}
        <button onClick={() => onBuyClick({...nft, image: getImageSource()})} className="buy-button">
          <FaShoppingCart /> Buy Now
        </button>
        <button onClick={onCompareClick} className="compare-button">
          <FaExchangeAlt /> Compare
        </button>
      </div>
    </div>
  );
};

const ComparisonModal = ({ onClose }) => {
  const features = [
    "Extra Airdrop at TGE",
    "EDG Token Rewards",
    "Partner Project Airdrop",
    "Multiple Token Rewards",
    "Smartphone Project Access",
    "First Batch Smartphone Purchase",
    "Additional Benefits"
  ];

  const getNFTFeature = (nftType, feature) => {
    const isLite = nftType === 'lite';
    const isPro = nftType === 'pro';

    switch (feature) {
      case "Extra Airdrop at TGE":
        return "Yes";
      case "EDG Token Rewards":
        return isLite ? "3 months" : "1 year";
      case "Partner Project Airdrop":
        return "Yes";
      case "Multiple Token Rewards":
        return isPro ? "Yes (DOT, KSM, ETH)" : "No";
      case "Smartphone Project Access":
        return isPro ? "Early Access" : "No";
      case "First Batch Smartphone Purchase":
        return isPro ? "Yes (Exclusive)" : "No";
      case "Additional Benefits":
        return isPro ? "Coming soon" : "No";
      default:
        return "N/A";
    }
  };

  return (
    <div className="comparison-modal">
      <div className="comparison-content">
        <h2>NFT Comparison</h2>
        <table className="comparison-table">
          <thead>
            <tr>
              <th>Feature</th>
              <th>Miniverse Lite</th>
              <th>Miniverse Pro</th>
            </tr>
          </thead>
          <tbody>
            {features.map(feature => (
              <tr key={feature}>
                <td>{feature}</td>
                <td>{getNFTFeature('lite', feature)}</td>
                <td>{getNFTFeature('pro', feature)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

const NFTs = () => {
  const [nfts, setNfts] = useState([]);
  const [userNFTs, setUserNFTs] = useState([]);
  const [activeTab, setActiveTab] = useState('mint');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [showComparison, setShowComparison] = useState(false);
  const { setPurchaseId } = usePurchaseContext();

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        setLoading(true);
        const [catalogueData, userNftsData] = await Promise.all([
          getNFTCatalogue(),
          getUserNFTs()
        ]);
        setNfts(catalogueData);
        setUserNFTs(userNftsData);
      } catch (error) {
        console.error('Failed to fetch NFT data:', error);
        setError('Failed to load NFTs. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchNFTs();
  }, []);

  const handleBuyClick = (nft) => {
    setSelectedNFT(nft);
  };

  const handleCompareClick = () => {
    setShowComparison(true);
  };

  const handleConfirmPurchase = async () => {
    try {
      const response = await initiateOxapayPayment(selectedNFT.id, 1);
      if (response.paymentData && response.paymentData.payLink) {
        setPurchaseId(response.purchaseId);
        
        // Use Telegram's openLink method to open the payment URL
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.openLink(response.paymentData.payLink, {
            try_instant_view: false,
          });
        } else {
          // Fallback for when Telegram's Web App API is not available
          window.open(response.paymentData.payLink, '_blank');
        }
        
        setSelectedNFT(null);
      } else {
        throw new Error('Invalid payment data received from the server');
      }
    } catch (error) {
      console.error('Purchase error:', error);
      setError(`Error during purchase: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <div className="skeleton-loader">
        <div className="skeleton-nft-card"></div>
        <div className="skeleton-nft-card"></div>
        <div className="skeleton-nft-card"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="nfts-page">
      <div className="wallet-tab-menu">
        <button
          className={`tab-button ${activeTab === 'mint' ? 'active' : ''}`}
          onClick={() => setActiveTab('mint')}
        >
          <FaWallet /> Mint NFT
        </button>
        <button
          className={`tab-button ${activeTab === 'my-nfts' ? 'active' : ''}`}
          onClick={() => setActiveTab('my-nfts')}
        >
          <FaUser /> My NFTs
        </button>
      </div>

      {activeTab === 'mint' && (
        <div className="mint-section">
          <div className="nft-grid">
            {nfts.map((nft) => (
              <NFTCard 
                key={nft.id} 
                nft={nft} 
                onBuyClick={handleBuyClick} 
                onCompareClick={handleCompareClick}
              />
            ))}
          </div>
        </div>
      )}

      {activeTab === 'my-nfts' && <MyNFTs nfts={userNFTs} />}

      {selectedNFT && (
        <div className="checkout-modal">
          <div className="checkout-content">
            <h2>Confirm Purchase</h2>
            <div className="checkout-image-container">
              <img src={selectedNFT.image} alt={selectedNFT.name} className="checkout-nft-image" />
            </div>
            <h3>{selectedNFT.name}</h3>
            <p>Price: ${selectedNFT.price}</p>
            <div className="checkout-buttons">
              <button onClick={() => setSelectedNFT(null)} className="cancel-button">Cancel</button>
              <button onClick={handleConfirmPurchase} className="confirm-button">Confirm Purchase</button>
            </div>
          </div>
        </div>
      )}

      {showComparison && (
        <ComparisonModal 
          onClose={() => setShowComparison(false)} 
        />
      )}
    </div>
  );
};

export default NFTs;