import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useAppContext } from '../contexts/AppContext';
import { getGames, fetchCoinPoolBalance, getUserStats } from '../services/api';
import '../styles/Games.css';
import sendIcon from '../styles/img/send.svg';
import ShareDialog from '../components/Layout/ShareDialog';

// Banner images
import chillTheLionBanner from '../styles/img/chill-the-lion-banner.png';
import rabbitGameBanner from '../styles/img/rabbit-game-banner.png';

const MAX_POOL = 10000;
const REFRESH_INTERVAL = 5000; // 5 seconds

const Games = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const { 
    games, 
    setGames, 
    isLoading, 
    startLoading, 
    stopLoading, 
    error, 
    setError,
    clearError,
    coinPoolBalance,
    setCoinPoolBalance
  } = useAppContext();

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [cachedGames, setCachedGames] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(0);

  const potionContentRef = useRef(null);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  const fetchGames = useCallback(async () => {
    const now = Date.now();
    const cachedData = localStorage.getItem('cachedGames');
    
    if (cachedData && now - lastFetchTime < 300000) { // 5 minutes cache
      setCachedGames(JSON.parse(cachedData));
      return;
    }

    try {
      startLoading();
      clearError();
      const fetchedGames = await getGames();
      setGames(fetchedGames);
      setCachedGames(fetchedGames);
      localStorage.setItem('cachedGames', JSON.stringify(fetchedGames));
      setLastFetchTime(now);
    } catch (err) {
      console.error('Failed to fetch games:', err);
      setError('Failed to load games. Please try again later.');
    } finally {
      stopLoading();
    }
  }, [startLoading, stopLoading, setGames, setError, clearError, lastFetchTime]);

  const fetchPoolBalance = useCallback(async () => {
    try {
      const balance = await fetchCoinPoolBalance();
      setCoinPoolBalance(balance);
    } catch (error) {
      console.error('Failed to fetch coin pool balance:', error);
    }
  }, [setCoinPoolBalance]);

  const fetchUserStats = useCallback(async () => {
    try {
      const stats = await getUserStats();
      setUser(prevUser => ({ ...prevUser, ...stats }));
    } catch (error) {
      console.error('Failed to fetch user stats:', error);
    }
  }, [setUser]);

  useEffect(() => {
    fetchGames();
    fetchPoolBalance();
    fetchUserStats();
    const balanceIntervalId = setInterval(fetchPoolBalance, REFRESH_INTERVAL);
    const statsIntervalId = setInterval(fetchUserStats, REFRESH_INTERVAL);
    return () => {
      clearInterval(balanceIntervalId);
      clearInterval(statsIntervalId);
    };
  }, [fetchGames, fetchPoolBalance, fetchUserStats]);

  useEffect(() => {
    if (potionContentRef.current) {
      const fillPercentage = (coinPoolBalance / MAX_POOL) * 100;
      potionContentRef.current.style.setProperty('--fill-percentage', `${fillPercentage}%`);
    }
  }, [coinPoolBalance]);

  const preventDefaultAndNavigate = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/games/${path}`);
  }, [navigate]);

  const handleInvite = () => {
    setIsShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setIsShareDialogOpen(false);
  };

  const inviteLink = `https://t.me/MiniVerseNetworkBot/app?startapp=ref_${user?.referral_code}`;
  const inviteMessage = `Join me on MiniVerse! 🚀 Use my referral link: ${inviteLink} and dive into daily games, tasks, and earn exciting airdrops! 🎮✨`;

  const getBannerImage = (gameName) => {
    switch (gameName.toLowerCase()) {
      case 'chill the lion':
        return chillTheLionBanner;
      case 'rabbit game':
        return rabbitGameBanner;
      default:
        return null;
    }
  };

  if (isLoading && !cachedGames) {
    return (
      <div className="skeleton-loader">
        <div className="skeleton-game-card"></div>
        <div className="skeleton-game-card"></div>
        <div className="skeleton-game-card"></div>
      </div>
    );
  }

  if (error && !cachedGames) {
    return (
      <div className="games-error-message">
        {error}
        <button onClick={fetchGames}>Try Again</button>
      </div>
    );
  }

  const displayGames = cachedGames || games;

  return (
    <div className="games-page-container">
      <div className="games-page-header">
        <div className="games-balance-meter">
          <div className="potion" ref={potionContentRef}>
            <div className="potion-liquid" style={{height: '50%'}}></div>
          </div>
          <div className="games-balance-display">
            {Math.floor(coinPoolBalance)}/{MAX_POOL}
          </div>
        </div>
        <div className="game-pass-ticket">
          <div className="ticket-left">
            <div className="pass-number">{user?.game_passes || 0}</div>
          </div>
          <div className="ticket-right">
            <div className="pass-title">PASSES</div>
          </div>
        </div>
      </div>
      
      <div className="games-list-container">
        {displayGames.map((game) => (
          <React.Fragment key={game.id}>
            <div className="games-card">
              <img src={getBannerImage(game.name)} alt={game.name} className="games-card-banner" />
              <button
                className="games-play-button"
                onClick={(e) => preventDefaultAndNavigate(e, game.path)}
              >
                Play <img src={sendIcon} alt="Play" className="send-icon" />
              </button>
            </div>
            {game.name === 'Rabbit Game' && (
              <div className='invite-friend'>
                <span>Invite your friends to earn more passes</span>
                <button className="invite-button" onClick={handleInvite}>
                  Invite
                </button>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <ShareDialog
        isOpen={isShareDialogOpen}
        onClose={handleCloseShareDialog}
        inviteMessage={inviteMessage}
        inviteLink={inviteLink}
      />
    </div>
  );
};

export default Games;