import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { getTasks, startTask, claimTask, retryTask } from '../services/api';
import { FaBolt } from 'react-icons/fa';
import confetti from 'canvas-confetti';
import '../styles/Tasks.css';

const CATEGORIES = ['Socials', 'OnChain', 'Partners', 'Bonus'];

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All');
  const [loadingTaskIds, setLoadingTaskIds] = useState(new Set());
  const [claimableTaskIds, setClaimableTaskIds] = useState(new Set());
  const [completedTasks, setCompletedTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);
  const [coinsEarned, setCoinsEarned] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { startLoading, stopLoading, setError } = useAppContext();

  const fetchTasks = useCallback(async () => {
    startLoading();
    try {
      const tasksData = await getTasks();
      setTasks(tasksData);
      setTotalTasks(tasksData.length);
      setCompletedTasks(tasksData.filter(task => task.status === 'completed').length);
      setCoinsEarned(tasksData.reduce((sum, task) => task.status === 'completed' ? sum + task.reward : sum, 0));
      localStorage.setItem('tasksData', JSON.stringify(tasksData));
    } catch (error) {
      setError('Failed to fetch tasks. Please try again.');
    } finally {
      stopLoading();
      setIsInitialLoad(false);
    }
  }, [startLoading, stopLoading, setError]);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  useEffect(() => {
    const cachedTasks = localStorage.getItem('tasksData');
    if (cachedTasks) {
      const parsedTasks = JSON.parse(cachedTasks);
      setTasks(parsedTasks);
      setTotalTasks(parsedTasks.length);
      setCompletedTasks(parsedTasks.filter(task => task.status === 'completed').length);
      setCoinsEarned(parsedTasks.reduce((sum, task) => task.status === 'completed' ? sum + task.reward : sum, 0));
      setIsInitialLoad(false);
    }
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    const inProgressTasks = tasks.filter(task => task.status === 'in_progress');
    setClaimableTaskIds(new Set(inProgressTasks.map(task => task.id)));
  }, [tasks]);

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const openLinkNatively = useCallback((url) => {
    if (!isValidUrl(url)) {
      setError('Invalid URL provided.');
      return;
    }

    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.ready();
        if (url.startsWith('https://t.me/') || url.startsWith('tg://')) {
          window.Telegram.WebApp.openTelegramLink(url);
        } else {
          window.Telegram.WebApp.openLink(url);
        }
      } else {
        window.open(url, '_blank');
      }
    } catch (error) {
      setError('Error opening link. It has been opened in a new tab.');
      window.open(url, '_blank');
    }
  }, [setError]);

  const triggerConfetti = () => {
    confetti({
      particleCount: 200,
      spread: 80,
      origin: { y: 0.6 },
      colors: ['#FFD700', '#FFA500', '#FF4500', '#8A2BE2', '#4B0082']
    });
  };

  const handleTaskAction = useCallback(async (task, action) => {
    setLoadingTaskIds(prev => new Set(prev).add(task.id));
    try {
      let result;
      switch (action) {
        case 'start':
        case 'retry':
          if (task.url) {
            openLinkNatively(task.url);
          }
          result = action === 'start' ? await startTask(task.id) : await retryTask(task.id);
          if (result.success) {
            setTimeout(() => {
              setClaimableTaskIds(prev => new Set(prev).add(task.id));
              setLoadingTaskIds(prev => {
                const newSet = new Set(prev);
                newSet.delete(task.id);
                return newSet;
              });
            }, 30000); // 60 seconds timer
          }
          break;
        case 'claim':
          result = await claimTask(task.id);
          if (result.success) {
            setClaimableTaskIds(prev => {
              const newSet = new Set(prev);
              newSet.delete(task.id);
              return newSet;
            });
            triggerConfetti();
          }
          break;
        default:
          throw new Error('Invalid action');
      }

      if (result.success) {
        await fetchTasks();
      } else {
        setError(result.message || `Failed to ${action} task. Please try again.`);
      }
    } catch (error) {
      setError(`Failed to ${action} task. Please try again.`);
    } finally {
      setLoadingTaskIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(task.id);
        return newSet;
      });
    }
  }, [fetchTasks, openLinkNatively, setError]);

  const renderTaskItem = useCallback((task) => {
    const isLoading = loadingTaskIds.has(task.id);
    const isClaimable = claimableTaskIds.has(task.id);

    return (
      <div key={task.id} className="task-item">
        <div className="task-reward">
          <FaBolt />
          <span>{task.reward}</span>
        </div>
        <div className="task-content">
          <h3>{task.description}</h3>
          <p>{task.category}</p>
        </div>
        <div className="task-actions">
          {task.status === 'not_started' && (
            <button 
              onClick={() => handleTaskAction(task, 'start')}
              className="task-action-button start"
              disabled={isLoading}
            >
              Start
            </button>
          )}
          {task.status === 'in_progress' && (
            <button 
              onClick={() => handleTaskAction(task, 'claim')}
              className={`task-action-button claim ${isLoading ? 'loading' : ''}`}
              disabled={isLoading || !isClaimable}
            >
              <span className="button-text">Claim</span>
              <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </button>
          )}
          {task.status === 'failed' && (
            <button 
              onClick={() => handleTaskAction(task, 'retry')}
              className="task-action-button retry"
              disabled={isLoading}
            >
              Retry
            </button>
          )}
          {task.status === 'completed' && (
            <div className="task-status completed">
              <span className="checkmark">✓</span>
            </div>
          )}
        </div>
        {task.is_new && <span className="new-badge">New</span>}
      </div>
    );
  }, [handleTaskAction, loadingTaskIds, claimableTaskIds]);

  const renderSkeletonLoader = () => (
    <div className="task-item skeleton">
      <div className="task-reward skeleton-box"></div>
      <div className="task-content">
        <div className="skeleton-box" style={{ width: '70%', height: '20px' }}></div>
        <div className="skeleton-box" style={{ width: '40%', height: '16px', marginTop: '8px' }}></div>
      </div>
      <div className="task-actions">
        <div className="skeleton-box" style={{ width: '80px', height: '36px' }}></div>
      </div>
    </div>
  );

  const filteredTasks = useMemo(() => 
    tasks.filter(task => 
      activeCategory === 'All' || 
      (activeCategory === 'New' ? task.is_new : task.category === activeCategory)
    ),
    [tasks, activeCategory]
  );

  const progressPercentage = (completedTasks / totalTasks) * 100;

  return (
    <div className="tasks-page">
      <div className="tasks-header">
        <div className="progress-circle">
          <svg viewBox="0 0 36 36" className="circular-chart">
            <path className="circle-bg"
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path className="circle"
              strokeDasharray={`${progressPercentage}, 100`}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
          <div className="progress-content">
            <div className="progress-icon">⚡</div>
            <div className="progress-number">{completedTasks}</div>
            <div className="progress-total">/{totalTasks}</div>
          </div>
        </div>
        <div className="coins-earned">
          <span>{coinsEarned}</span>
          <span>Coins Earned</span>
        </div>
      </div>

      <div className="category-tabs">
        <button
          className={`category-tab ${activeCategory === 'All' ? 'active' : ''}`}
          onClick={() => setActiveCategory('All')}
        >
          All
        </button>
        <button
          className={`category-tab ${activeCategory === 'New' ? 'active' : ''}`}
          onClick={() => setActiveCategory('New')}
        >
          New
        </button>
        {CATEGORIES.map(category => (
          <button
            key={category}
            className={`category-tab ${activeCategory === category ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="task-list">
        {isInitialLoad
          ? Array(5).fill().map((_, index) => <React.Fragment key={index}>{renderSkeletonLoader()}</React.Fragment>)
          : filteredTasks.map(renderTaskItem)}
      </div>
    </div>
  );
};

export default Tasks;