import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://edg.parodyscan.xyz/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  const telegramInitData = localStorage.getItem('tgInitData');
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  if (telegramInitData) {
    config.headers['X-Telegram-Init-Data'] = telegramInitData;
  }
  
  return config;
}, (error) => {
  return Promise.reject(error);
});

const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    return new Promise((resolve) => {
      timeoutId = setTimeout(() => resolve(fn(...args)), delay);
    });
  };
};

let gamesCache = null;
let userStatsCache = null;
const CACHE_DURATION = 60000;

export const setTelegramInitData = (initData) => {
  api.defaults.headers.common['X-Telegram-Init-Data'] = initData;
  localStorage.setItem('tgInitData', initData);
};

export const initializeUser = async (userData) => {
  console.log('Initializing user with data:', userData);
  try {
    const response = await api.post('/users/initialize', userData);
    console.log('Server response:', response.data);
    if (response.data.token) {
      localStorage.setItem('authToken', response.data.token);
    }
    return response.data;
  } catch (error) {
    console.error('Error initializing user:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserStats = async () => {
  if (userStatsCache && (Date.now() - userStatsCache.timestamp < CACHE_DURATION)) {
    return userStatsCache.data;
  }
  try {
    const response = await api.get('/users/stats');
    userStatsCache = { data: response.data, timestamp: Date.now() };
    return response.data;
  } catch (error) {
    console.error('Error fetching user stats:', error.response?.data || error.message);
    throw error;
  }
};

export const getReferredFriends = async () => {
  try {
    const response = await api.get('/users/referred-friends');
    return response.data;
  } catch (error) {
    console.error('Error fetching referred friends:', error.response?.data || error.message);
    throw error;
  }
};

export const getGames = debounce(async () => {
  if (gamesCache && (Date.now() - gamesCache.timestamp < CACHE_DURATION)) {
    return gamesCache.data;
  }
  try {
    const response = await api.get('/games');
    gamesCache = { data: response.data, timestamp: Date.now() };
    return response.data;
  } catch (error) {
    console.error('Error fetching games:', error.response?.data || error.message);
    throw error;
  }
}, 300);

export const startGame = async (gameType) => {
  try {
    const response = await api.post('/games/start', { game_type: gameType });
    return response.data;
  } catch (error) {
    console.error('Error starting game:', error.response?.data || error.message);
    throw error;
  }
};

export const endGame = async (gameId, score) => {
  try {
    const response = await api.post('/games/end', { game_id: gameId, score });
    return response.data;
  } catch (error) {
    console.error('Error ending game:', error.response?.data || error.message);
    throw error;
  }
};

export const getHoldToEarnStatus = async () => {
  try {
    const response = await api.get('/games/hold-to-earn');
    return response.data;
  } catch (error) {
    console.error('Error getting hold-to-earn status:', error.response?.data || error.message);
    throw error;
  }
};

export const updateHoldToEarn = async (duration, coins) => {
  try {
    const response = await api.post('/games/hold-to-earn', { duration, coins });
    return response.data;
  } catch (error) {
    console.error('Error updating hold-to-earn:', error.response?.data || error.message);
    throw error;
  }
};

export const updateWalletAddress = async (address) => {
  try {
    const response = await api.post('/users/update-wallet', { wallet_address: address });
    return response.data;
  } catch (error) {
    console.error('Error updating wallet address:', error.response?.data || error.message);
    throw error;
  }
};

export const getTasks = async () => {
  try {
    const response = await api.get('/tasks');
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error.response?.data || error.message);
    throw error;
  }
};

export const startTask = async (taskId) => {
  try {
    const response = await api.post(`/tasks/${taskId}/start`);
    return response.data;
  } catch (error) {
    console.error('Error starting task:', error.response?.data || error.message);
    throw error;
  }
};

export const claimTask = async (taskId) => {
  try {
    const response = await api.post(`/tasks/${taskId}/claim`);
    return response.data;
  } catch (error) {
    console.error('Error claiming task:', error.response?.data || error.message);
    throw error;
  }
};

export const retryTask = async (taskId) => {
  try {
    const response = await api.post(`/tasks/${taskId}/retry`);
    return response.data;
  } catch (error) {
    console.error('Error retrying task:', error.response?.data || error.message);
    throw error;
  }
};

export const getLeaderboard = async (page = 1, limit = 20) => {
  try {
    const response = await api.get(`/users/leaderboard?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching leaderboard:', error);
    throw error;
  }
};

export const getWalletBalance = async () => {
  try {
    const response = await api.get('/wallet/balance');
    return response.data;
  } catch (error) {
    console.error('Error fetching wallet balance:', error.response?.data || error.message);
    throw error;
  }
};

export const holdToEarn = async (duration) => {
  try {
    const response = await api.post('/wallet/hold-to-earn', { duration });
    return response.data;
  } catch (error) {
    console.error('Error performing Hold to Earn:', error.response?.data || error.message);
    throw error;
  }
};

export const fetchCoinPoolBalance = async () => {
  try {
    const response = await api.get('/games/coin-pool-balance');
    return response.data.balance;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Authentication error when fetching coin pool balance:', error.response.data);
      return 0;
    } else {
      console.error('Error fetching coin pool balance:', error.response?.data || error.message);
      throw error;
    }
  }
};

export const claimCoins = async (amount) => {
  try {
    const response = await api.post('/games/claim-coins', { amount });
    if (response.data && response.data.newPoolBalance !== undefined) {
      return {
        userUpdate: response.data.userUpdate,
        newPoolBalance: response.data.newPoolBalance,
        claimedAmount: response.data.claimedAmount || amount,
        dailyEarnings: response.data.dailyEarnings
      };
    } else {
      console.error('Unexpected response structure:', response.data);
      throw new Error('Unexpected response structure from server');
    }
  } catch (error) {
    console.error('Error claiming coins:', error.response?.data || error.message);
    throw error;
  }
};

export const getRabbitGameRemainingTime = async () => {
  try {
    const response = await api.get('/games/rabbit/remaining-time');
    return response.data;
  } catch (error) {
    console.error('Error fetching Rabbit game remaining time:', error.response?.data || error.message);
    throw error;
  }
};

export const startRabbitGame = async () => {
  try {
    console.log('Sending start Rabbit game request');
    const response = await api.post('/games/rabbit/start');
    console.log('Start Rabbit game response:', response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.error === 'Not enough game passes') {
      console.error('Not enough game passes to start Rabbit game');
      throw new Error('Not enough game passes');
    }
    console.error('Error starting Rabbit game:', error.response?.data || error.message);
    throw error;
  }
};

export const endRabbitGame = async (score) => {
  try {
    const response = await api.post('/games/rabbit/end', { score });
    return response.data;
  } catch (error) {
    console.error('Error ending Rabbit game:', error.response?.data || error.message);
    throw error;
  }
};

export const getRabbitGameHighScore = async () => {
  try {
    const response = await api.get('/games/rabbit/highscore');
    return response.data.highScore;
  } catch (error) {
    console.error('Error fetching Rabbit game high score:', error.response?.data || error.message);
    throw error;
  }
};

export const updateRabbitGameScore = async (score) => {
  try {
    const response = await api.post('/games/rabbit/update', { score });
    return response.data;
  } catch (error) {
    console.error('Error updating Rabbit game score:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserGamePasses = async () => {
  try {
    const response = await api.get('/users/game-passes');
    return response.data.gamePasses;
  } catch (error) {
    console.error('Error fetching user game passes:', error.response?.data || error.message);
    throw error;
  }
};

export const checkDailyLogin = async () => {
  try {
    const response = await api.get('/users/daily-login');
    return response.data;
  } catch (error) {
    console.error('Error checking daily login:', error.response?.data || error.message);
    throw error;
  }
};

export const getLoginStreak = async () => {
  try {
    const response = await api.get('/users/login-streak');
    return response.data;
  } catch (error) {
    console.error('Error getting login streak:', error.response?.data || error.message);
    throw error;
  }
};

export const getEarningsChart = async () => {
  try {
    const response = await api.get('/users/earnings-chart');
    return response.data;
  } catch (error) {
    console.error('Error getting earnings chart:', error.response?.data || error.message);
    throw error;
  }
};

export const updateProfilePhoto = async (telegram_id) => {
  try {
    const response = await api.post('/users/update-profile-photo', { telegram_id });
    return response.data;
  } catch (error) {
    console.error('Error updating profile photo:', error.response?.data || error.message);
    throw error;
  }
};

export const claimDailyBonus = async () => {
  try {
    const response = await api.post('/users/claim-daily-bonus');
    return response.data;
  } catch (error) {
    console.error('Error claiming daily bonus:', error.response?.data || error.message);
    throw error;
  }
};

export const checkDailyBonus = async () => {
  try {
    const response = await api.get('/users/check-daily-bonus');
    return response.data;
  } catch (error) {
    console.error('Error checking daily bonus:', error.response?.data || error.message);
    throw error;
  }
};

export const getDailyEarnings = async () => {
  try {
    const response = await api.get('/users/daily-earnings');
    return response.data;
  } catch (error) {
    console.error('Error fetching daily earnings:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserProfilePhoto = async (userId) => {
  try {
    const response = await api.get(`/users/profile-picture/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile photo:', error.response?.data || error.message);
    return null;
  }
};

export const fetchAndUpdateProfilePhoto = async (telegramId) => {
  try {
    const response = await api.post('/users/fetch-update-profile-photo', { telegram_id: telegramId });
    return response.data;
  } catch (error) {
    console.error('Error fetching and updating profile photo:', error.response?.data || error.message);
    throw error;
  }
};

export const getNFTCatalogue = async () => {
  try {
    const response = await api.get('/nfts/catalogue');
    return response.data;
  } catch (error) {
    console.error('Error fetching NFT catalogue:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserNFTs = async () => {
  try {
    const response = await api.get('/nfts/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching user NFTs:', error.response?.data || error.message);
    throw error;
  }
};

// Edgeware wallet-related API calls
export const getEdgewareBalance = async () => {
  try {
    const response = await api.get('/wallet/edgeware/balance');
    return response.data;
  } catch (error) {
    console.error('Error fetching Edgeware balance:', error.response?.data || error.message);
    throw error;
  }
};

export const sendEdgeware = async (amount, destinationType, destinationAddress) => {
  try {
    const response = await api.post('/wallet/edgeware/send', { amount, destinationType, destinationAddress });
    return response.data;
  } catch (error) {
    console.error('Error sending Edgeware:', error.response?.data || error.message);
    throw error;
  }
};

export const stakeEdgeware = async (amount, validatorAddress) => {
  try {
    const response = await api.post('/wallet/edgeware/stake', { amount, validatorAddress });
    return response.data;
  } catch (error) {
    console.error('Error staking Edgeware:', error.response?.data || error.message);
    throw error;
  }
};

export const unstakeEdgeware = async (amount) => {
  try {
    const response = await api.post('/wallet/edgeware/unstake', { amount });
    return response.data;
  } catch (error) {
    console.error('Error unstaking Edgeware:', error.response?.data || error.message);
    throw error;
  }
};

export const withdrawUnstakedEdgeware = async (unstakingRequestId) => {
  try {
    const response = await api.post('/wallet/edgeware/withdraw-unstaked', { unstakingRequestId });
    return response.data;
  } catch (error) {
    console.error('Error withdrawing unstaked Edgeware:', error.response?.data || error.message);
    throw error;
  }
};

export const getEdgewareTransactions = async (page = 1) => {
  try {
    const response = await api.get(`/wallet/edgeware/transactions?page=${page}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Edgeware transactions:', error.response?.data || error.message);
    throw error;
  }
};

export const getEdgewareStakingInfo = async () => {
  try {
    const response = await api.get('/wallet/edgeware/staking-info');
    return response.data;
  } catch (error) {
    console.error('Error fetching Edgeware staking info:', error.response?.data || error.message);
    throw error;
  }
};

export const getEdgewareValidators = async () => {
  try {
    const response = await api.get('/wallet/edgeware/validators');
    return response.data;
  } catch (error) {
    console.error('Error fetching Edgeware validators:', error.response?.data || error.message);
    throw error;
  }
};

export const estimateFees = async (amount, recipient, addressType) => {
  try {
    const response = await api.post('/wallet/edgeware/estimate-fees', { amount, recipient, addressType });
    return response.data.fee;
  } catch (error) {
    console.error('Error estimating Edgeware fees:', error.response?.data || error.message);
    throw error;
  }
};

export const refreshEdgewareData = async () => {
  try {
    const response = await api.post('/wallet/edgeware/refresh');
    return response.data;
  } catch (error) {
    console.error('Error refreshing Edgeware data:', error.response?.data || error.message);
    throw error;
  }
};

export const getTotalStakableBalance = async () => {
  try {
    const response = await api.get('/wallet/edgeware/total-stakable-balance');
    return response.data.balance;
  } catch (error) {
    console.error('Error fetching total stakable balance:', error.response?.data || error.message);
    throw error;
  }
};

export const getUnstakingRequests = async () => {
  try {
    const response = await api.get('/wallet/edgeware/unstaking-requests');
    return response.data;
  } catch (error) {
    console.error('Error fetching unstaking requests:', error.response?.data || error.message);
    throw error;
  }
};

export const estimateTotalFees = async (operation, params) => {
  try {
    const response = await api.post('/wallet/edgeware/estimate-total-fees', { operation, params });
    return response.data.totalFee;
  } catch (error) {
    console.error('Error estimating total fees:', error.response?.data || error.message);
    throw error;
  }
};
export const getAvailablePaymentMethods = async () => {
  try {
    const response = await api.get('/payment/payment-methods');
    return response.data;
  } catch (error) {
    console.error('Error fetching payment methods:', error);
    throw error;
  }
};

export const createPayment = async (nftId, paymentMethod, amount) => {
  try {
    const response = await api.post('/payment/create', { nftId, paymentMethod, amount });
    return response.data;
  } catch (error) {
    console.error('Error creating payment:', error);
    throw error;
  }
};
export const getNFTMetadata = async (nftId) => {
  try {
    const response = await api.get(`/nfts/${nftId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching NFT metadata:', error);
    throw error;
  }
};
export const getUserEVMAddress = async () => {
  try {
    const response = await api.get('/nfts/user-evm-address');
    return response.data.evm_address;
  } catch (error) {
    console.error('Error fetching user EVM address:', error);
    throw error;
  }
};
export const initiatePurchase = async (nftId, quantity, paymentMethod, selectedCoin) => {
  try {
    const response = await api.post('/nfts/initiate-purchase', {
      nftId,
      quantity,
      paymentMethod,
      selectedCoin
    });
    return response.data;
  } catch (error) {
    console.error('Error initiating purchase:', error.response?.data || error.message);
    throw error;
  }
};
export const buyNFT = async (purchaseId) => {
  try {
    const response = await api.post('/nfts/buy', { purchaseId });
    return response.data;
  } catch (error) {
    console.error('Error buying NFT:', error.response?.data || error.message);
    throw error;
  }
};
export const getPaymentMethods = async () => {
  try {
    const response = await api.get('/payments/payment-methods');
    return response.data;
  } catch (error) {
    console.error('Error fetching payment methods:', error.response?.data || error.message);
    throw error;
  }
};
export const getNFTById = async (id) => {
  try {
    const response = await api.get(`/nfts/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching NFT details:', error.response?.data || error.message);
    throw error;
  }
};
export const checkPaymentStatus = async (purchaseId) => {
  try {
    const response = await api.get(`/payments/check-payment-status/${purchaseId}`);
    return response.data;
  } catch (error) {
    console.error('Error checking payment status:', error.response?.data || error.message);
    throw error;
  }
};
export const getPurchaseDetails = async (purchaseId) => {
  try {
    const response = await api.get(`/nfts/purchase-details/${purchaseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching purchase details:', error.response?.data || error.message);
    throw error;
  }
};


export const initiateOxapayPayment = async (nftId, quantity, selectedCoin) => {
  try {
    const response = await api.post('/payments/initiate-oxapay-payment', {
      nftId,
      quantity,
      selectedCoin,
    });
    return response.data;
  } catch (error) {
    console.error('Error initiating Oxapay payment:', error.response?.data || error.message);
    throw error;
  }
};
export const cancelPayment = async (purchaseId) => {
  try {
    const response = await api.post(`/payments/cancel-payment/${purchaseId}`);
    return response.data;
  } catch (error) {
    console.error('Error cancelling payment:', error);
    throw error;
  }
};
export const updateTONWalletAddress = async (address) => {
  try {
    console.log('Sending update request with address:', address);
    const response = await api.post('/wallet/update-ton', { address }); // Changed from 'wallet_address' to 'address'
    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error(
      'Error updating TON wallet address:',
      error.response?.data || error.message
    );
    throw error;
  }
};
export const getWalletInfo = async () => {
  try {
    const response = await api.get('/wallet');
    return response.data;
  } catch (error) {
    console.error('Error fetching wallet info:', error.response?.data || error.message);
    throw error;
  }
};
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized access. Please re-authenticate.');
    }
    return Promise.reject(error);
  }
);

export default api;