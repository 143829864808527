import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { UserProvider, useUser } from './contexts/UserContext';
import { PurchaseProvider } from './contexts/PurchaseContext'; 
import { AppProvider, useAppContext } from './contexts/AppContext';
import BottomNavigation from './components/Layout/BottomNavigation';
import Home from './pages/Home';
import Games from './pages/Games';
import Tasks from './pages/Tasks';
import Leaderboard from './pages/Leaderboard';
import Wallet from './pages/Wallet';
import EdgewareWallet from './pages/EdgewareWallet';
import NFTs from './pages/NFTs';
import Checkout from './components/Checkout/Checkout';
import PaymentSuccess from './components/Checkout/PaymentSuccess';
import PaymentProcessing from './components/Checkout/PaymentProcessing';
import PaymentCancelled from './components/Checkout/PaymentCancelled';
import OxapayCheckout from './components/Checkout/OxapayCheckout';
//import SplashScreen from './components/Layout/SplashScreen';
import ErrorPage from './pages/ErrorPage';
import './styles/global.css';

// Import individual game components
import LionGame from './components/Games/LionGame';
import RabbitGame from './components/Games/RabbitGame';
import ShyBirdGame from './components/Games/ShyBirdGame';

function AppContent() {
  const { isGlobalLoading } = useAppContext();
  const { loading: initializing, error: initError } = useUser();

  //if (initializing) {
  //  return <SplashScreen />;
 // }

  if (initError) {
    return <ErrorPage message={initError} />;
  }

  return (
    <div className="App">
      {isGlobalLoading && <div className="global-loader">Loading...</div>}
      <main className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/games" element={<Games />} />
          <Route path="/games/lion" element={<LionGame />} />
          <Route path="/games/rabbit" element={<RabbitGame />} />
          <Route path="/games/shybird" element={<ShyBirdGame />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/edgeware" element={<EdgewareWallet />} />
          <Route path="/nfts" element={<NFTs />} />
          <Route path="/nfts/:nftId" element={<NFTs />} />
          <Route path="/checkout/:purchaseId" element={<Checkout />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-cancelled" element={<PaymentCancelled />} />
          <Route path="/payment-processing" element={<PaymentProcessing />} />
          <Route path="/oxapay-checkout" element={<OxapayCheckout />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage message="Page not found" />} />
        </Routes>
      </main>
      <BottomNavigation />
    </div>
  );
}

function App() {
  return (
    <TonConnectUIProvider manifestUrl={`${process.env.REACT_APP_URL}/tonconnect-manifest.json`}>
      <UserProvider>
        <AppProvider>
          <PurchaseProvider>
            <Router>
              <AppContent />
            </Router>
          </PurchaseProvider>
        </AppProvider>
      </UserProvider>
    </TonConnectUIProvider>
  );
}

export default App;